import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import useStations from "../utils/hooks/useStations";

const CropPestManagementPage = () => {
  const h1Text = "NEWA | Integrated Pest Management Tools for Crop Production";
  const { station } = useStations();
  return (
    <Layout station={station} h1Text={h1Text}>
      <Seo title="Integrated Pest Management Crop Production Tools" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <p>
              <small>
                <a href="#Apples">Apples</a> | <a href="#Berries">Berries</a> |{" "}
                <a href="#Field-crops">Field Crops</a> |{" "}
                <a href="#Grapes">Grapes</a> |{" "}
                <a href="#Ornamentals">Ornamentals</a> |{" "}
                <a href="#Vegetables">Vegetables</a> |{" "}
                <a href="#Other-resources">Other resources</a> |{" "}
                <a href="#Other-systems">Other decision systems</a>
              </small>
            </p>

            <hr></hr>

            <p className="text-sm font-bold">
              Create a secure user account to customize and save your tool
              settings. Watch the quickstart video below to get started.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                href="https://newa.zendesk.com/hc/en-us/articles/4417360459799-Create-and-Configure-Your-User-Profile-3-minutes-"
              >
                Watch the Profile Quickstart Video
              </a>
            </p>

            <hr></hr>

            <h2 id="Apples">Apples</h2>
            <p>
              <small>
                <a href="#Apple-diseases">Diseases</a> |{" "}
                <a href="#Apple-insects">Insects</a> |{" "}
                <a href="#Apple-thinning-irrigation">Thinning and irrigation</a>{" "}
                |{" "}
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>
            <h3 id="Apple-diseases">Apple Disease Management</h3>

            <p>
              <Link to="/apple-scab">
                <b>Apple Scab</b>
              </Link>
              . Manage apple scab (<em>Venturia inaequalis</em>) fungicide
              inputs by tracking ascospore maturity, daily and cumulative
              ascospore discharge, and scab infection events.
            </p>

            <p>
              <Link to="/fire-blight">
                <b>Fire Blight</b>
              </Link>
              . Assess fire blight (<em>Erwinia amylovora</em>) blossom blight
              risk with Cougarblight and infection potential (EIP) assessments.
              Add your recent streptomycin application date to re-evaluate risk.
              A trauma blight tool helps track shoot blight symptom development.
            </p>

            <p>
              <Link to="/sooty-blotch-flyspeck">
                <b>Sooty Blotch and Fly Speck</b>
              </Link>
              . Track sooty blotch and fly speck disease potential to assess
              daily and forecasted infection risk.
            </p>

            <h3 id="Apple-insects">Apple Insect Pests</h3>

            <p>
              <Link to="/apple-maggot">
                <b>Apple Maggot</b>
              </Link>
              . This degree day model tracks base 50° F BE degree days to time
              red sphere trap deployment to manage apple maggot (
              <em>Rhagoletis pomonella</em>).
            </p>

            <p>
              <Link to="/codling-moth">
                <b>Codling Moth</b>
              </Link>
              . This tool predicts codling moth (<em>Cydia pomonella</em>) life
              stages with base 50° F BE degree days to identify treatment
              windows with management guidelines.
            </p>

            <p>
              <Link to="/obliquebanded-leafroller">
                <b>Obliquebanded Leafroller</b>
              </Link>
              . Using base 43° F BE degree days, this tool delineates
              obliquebanded leafroller (<em>Choristoneura rosaceana</em>)
              development, sampling strategies, and management guidelines.
            </p>

            <p>
              <Link to="/oriental-fruit-moth">
                <b>Oriental Fruit Moth</b>
              </Link>
              . This degree day tool (base 45° F BE) tracks oriental fruit moth
              (<em>Grapholita molesta</em>) development across three
              generations, identifies treatment windows, and provides management
              information.
            </p>

            <p>
              <Link to="/plum-curculio">
                <b>Plum Curculio</b>
              </Link>
              . This tool uses base 50° F BE degree days to estimate the
              emigration of plum curculio (<em>Conotrachelus nenuphar</em>) into
              the apple orchard following petal fall, the need for treatment and
              when treatment can cease.
            </p>

            <p>
              <Link to="/san-jose-scale">
                <b>San Jose Scale</b>
              </Link>
              . Follow San Jose scale (<em>Comstockaspis perniciosus</em>)
              development using base 50° F BE degree days to identify treatment
              windows along with management guidelines.
            </p>

            <p>
              <Link to="/spotted-tentiform-leafminer">
                <b>Spotted Tentiform Leafminer</b>
              </Link>
              . Using base 43°F BE degree days, this tool tracks spotted
              tentiform leafminer (<em>Phyllonorycter blancardella</em>)
              development, suggests when to hang pheromone traps and scout for
              leaf mines, as well as identifies treatment windows and provides
              management information.
            </p>

            <h3 id="Apple-thinning-irrigation">
              Apple Thinning and Irrigation
            </h3>

            <p>
              <Link to="/apple-carbohydrate-thinning">
                <b>Apple Carbohydrate Thinning</b>
              </Link>
              . By predicting photosynthesis and carbohydrate assimilation in
              your trees, this tool takes the guesswork out of fruit thinning
              apples and suggests thinner rates based on tree carbohydrate
              balance.
            </p>

            <p>
              <Link to="/pollen-tube-growth-model">
                <b>Pollen Tube Growth Model</b>
              </Link>
              . Accurately time apple blossom thinning using this tool that
              predicts pollen tube growth and king blossom pollination, based on
              your measured stylet lengths and the model’s calculated pollen
              tube growth rates.
            </p>

            <p>
              <Link to="/apple-irrigation">
                <b>Apple Irrigation</b>
              </Link>
              . Optimize water use in your irrigation system with the apple
              irrigation tool, based on orchard density, age, recorded
              precipitation and supplemental irrigation inputs.
            </p>

            <hr></hr>

            <h2 id="Berries">Berries</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <Link to="/blueberry-maggot">
                <b>Blueberry Maggot</b>
              </Link>
              . Optimize monitoring for blueberry maggot (
              <em>Rhagoletis mendax</em>) with this base 50°F BE degree day tool
              that predicts adult emergence and enhances your ability to use IPM
              to determine if insecticide treatments are needed.
            </p>

            <p>
              <Link to="/strawberry-diseases">
                <b>Strawberry Diseases</b>
              </Link>
              . Optimize fungicide applications for strawberry fruit rot
              diseases. The tool predicts the optimal timing of fungicide
              applications for Anthracnose and Grey Mold based on temperature
              and rainfall. The model can be used from bloom through harvest in
              Day-neutral and June-bearing plantings of strawberries.
            </p>

            <hr></hr>

            <h2 id="Field-crops">Field Crops</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <Link to="/alfalfa-weevil">
                <b>Alfalfa Weevil</b>
              </Link>
              . Follow base 48°F degree day accumulations to track alfalfa
              weevil (<em>Hypera postica</em>) life stages as part of your
              alfalfa forage management program.
            </p>

            <p>
              <Link to="/black-cutworm">
                <b>Black Cutworm</b>
              </Link>
              . Coming 2025.
            </p>

            <p>
              <Link to="/seedcorn-maggot">
                <b>Seedcorn Maggot</b>
              </Link>{" "}
              (<b>Improved predictions are coming in 2023 and 2024</b>). Monitor
              adult seedcorn maggots in the spring to avoid planting when
              maggots are in the soil.{" "}
              <a
                href="https://cals.cornell.edu/new-york-state-integrated-pest-management"
                target="blank"
                rel="noreferrer"
              >
                New York State Integrated Pest Management
              </a>{" "}
              and the{" "}
              <a
                href="https://cals.cornell.edu/katja-poveda"
                target="blank"
                rel="noreferrer"
              >
                Poveda Lab
              </a>{" "}
              at Cornell University are working to improve this model
              specifically for New York State field crop producers.
            </p>

            <p>
              <Link to="/true-armyworm">
                <b>True Armyworm</b>
              </Link>
              . Coming 2025.
            </p>

            <p>
              <Link to="/western-bean-cutworm">
                <b>Western Bean Cutworm</b>
              </Link>
              . Track western bean cutworm adult moth flights to efficiently
              schedule your monitoring and management efforts. Daily base 3.3°C
              degree days, accumulated degree days from March 1, and current
              flight completion status are provided.
            </p>

            <p>
              <Link to="/white-mold-in-beans">
                <b>White Mold in Beans</b>
              </Link>
              . Track the presence of <i>Sclerotinia sclerotiorum</i> apothecia,
              which produce ascospores that land on soybean or snap bean flowers
              and cause stem rot. Predictions are made with 30-day averages of
              temperature and windspeed, combined with user-provided information
              on canopy closure, irrigation status, and flowering status. This
              model uses{" "}
              <a
                href="https://doi.org/10.1094/PDIS-02-18-0245-RE"
                target="blank"
                rel="noreferrer"
              >
                published algorithms developed for midwest US soybean production
              </a>{" "}
              developed by{" "}
              <a
                href="https://plantpath.wisc.edu/faculty/damon-smith-2/"
                target="blank"
                rel="noreferrer"
              >
                University of Wisconsin-Madison researchers
              </a>
              .
            </p>

            <hr></hr>

            <h2 id="Grapes">Grapes</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <Link to="/grape-cold-hardiness">
                <b>Grape Cold Hardiness</b>
              </Link>
              . Track the cold hardiness of important grape cultivars in real
              time, and get a five day forecast of upcoming conditions.
            </p>

            <p>
              <Link to="/grape-diseases">
                <b>Grape Diseases</b>
              </Link>
              . Manage grape diseases with infection risk tools that also
              provide management guidelines for black rot (
              <em>Guignardia bidwellii</em>
              ), Phomopsis cane and leaf spot (<em>Phomopsis viticola</em>), and
              powdery mildew (<em>Erysiphe necator</em>).
            </p>

            <p>
              <Link to="/grape-berry-moth">
                <b>Grape Berry Moth</b>
              </Link>
              . This degree day tool estimates development of grape berry moth (
              <em>Parolobesia viteana</em>) generations, identifies treatment
              windows, and provides management guidelines.
            </p>

            <p>
              <Link to="/spotted-lanternfly">
                <b>Spotted Lanternfly</b>
              </Link>
              . Track the development of spotted lanternfly (
              <i>Lycorma delicatula</i>) from date of first egg mass detection
              in the fall, through overwintering periods and immature phases, to
              adult emergence in the next year.
            </p>

            <hr></hr>

            <h2 id="Ornamentals">Ornamentals</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <Link to="/conifer-pests">
                <b>Conifer Pests</b>
              </Link>
              . Monitor conifer insect pests through the year using base 50°F
              degree day accumulations to track development and get relevant IPM
              management information in real time.
            </p>

            <hr></hr>

            <h2 id="Vegetables">Vegetables</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <Link to="/beet-cercospora-leaf-spot">
                <b>Beet Cercospora Leaf Spot</b>
              </Link>
              . Manage Cercospora leaf spot (<em>Cercospora beticola</em>) in
              table beet with this tool that predicts 2-day, 14-day, 21-day, and
              season-long infection risk.
            </p>

            <p>
              <Link to="/cabbage-maggot">
                <b>Cabbage Maggot</b>
              </Link>
              . Use base 40°F BE degree days to identify the critical treatment
              timing before cabbage maggot (<em>Delia radicum</em>) can infest
              your crucifer crops. Treatment guidelines include organic options.
            </p>

            <p>
              <Link to="/onion-diseases">
                <b>Onion Diseases</b>
              </Link>
              . These risk assessment tools forecast the infection potential for
              Botrytis leaf blight or blast (<em>Botrytis squamosa</em>), onion
              downy mildew (<em>Peronospora destructor</em>), and purple blotch
              (<em>Alternaria porri</em>).
            </p>

            <p>
              <Link to="/onion-maggot">
                <b>Onion Maggot</b>
              </Link>
              . Monitor base 40°F BE daily and accumulated degree days to track
              critical treatment timing for onion maggot (<em>Delia antiqua</em>
              ) to protect your crop from this pest.
            </p>

            <p>
              <a
                href="http://newa.nrcc.cornell.edu/newaDisease/potato_for"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Potato Diseases</b>
              </a>
              . <b>(Legacy resource)</b> Ascertain infection risk using P-days
              for early blight (<em>Alternaria solani</em>) and Blitecast for
              late blight (<em>Phytophthora infestans</em>) to assess the need
              for targeted management.
            </p>

            <p>
              <a
                href="http://newa.nrcc.cornell.edu/newaDisease/tomato_for"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Tomato Diseases</b>
              </a>
              . <b>(Legacy resource)</b> Manage tomato diseases more effectively
              using TOMCAST for early blight (<em>Alternaria solani</em>),
              Septoria leaf spot (<em>Septoria lycopersici</em>), and
              anthracnose (<em>Colletotrichum coccodes</em>) and using Blitecast
              for late blight (<em>Phytophthora infestans</em>) to identify
              infection risk.
            </p>

            <p>
              <Link to="/white-mold-in-beans">
                <b>White Mold in Beans</b>
              </Link>
              . Track the presence of <i>Sclerotinia sclerotiorum</i> apothecia,
              which produce ascospores that land on soybean or snap bean flowers
              and cause stem rot. Predictions are made with 30-day averages of
              temperature and windspeed, combined with user-provided information
              on canopy closure, irrigation status, and flowering status. This
              model uses{" "}
              <a
                href="https://doi.org/10.1094/PDIS-02-18-0245-RE"
                target="blank"
                rel="noreferrer"
              >
                published algorithms developed for midwest US soybean production
              </a>{" "}
              developed by{" "}
              <a
                href="https://plantpath.wisc.edu/faculty/damon-smith-2/"
                target="blank"
                rel="noreferrer"
              >
                University of Wisconsin-Madison researchers
              </a>
              .
            </p>

            <hr></hr>

            <h2 id="Other-resources">Other Resources</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <a
                href="https://benedick.sebs.rutgers.edu/BlueberryWeather/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Blueberry Phenology Tool</b>
              </a>
              . Hosted by the Rutgers University Blueberry and Cranberry
              Research Station this tool calculates blueberry degree days and
              chilling units for selected sites in New Jersey.
            </p>

            <p>
              <a
                href="http://climatesmartfarming.org/tools/csf-apple-freeze-probability/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Climate Smart Farming Apple Freeze Risk</b>
              </a>
              . Hosted by the Climate Smart Farming Program at Cornell
              University, this tool evaluates apple freeze damage risk for sites
              in the Northeast.
            </p>

            <p>
              <a
                href="https://digitalcommons.usu.edu/cgi/viewcontent.cgi?article=1643&amp;context=extension_curall"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Critical Temperatures for Frost Damage on Fruit Trees</b>
              </a>
              .Hosted by Utah State University Extension, this PDF resource
              lists Fahrenheit temperatures for each stage of fruit development
              at which 10% and 90% bud kill occurs after 30 minutes of exposure
              for different tree fruit crops.
            </p>

            <p>
              <a
                href="https://cdm.ipmpipe.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Cucurbit Downy Mildew</b>
              </a>
              . Hosted by the Cucurbit Downy Mildew ipmPIPE Forecasting Project,
              this tool provides regional and national forecasts of cucurbit
              downy mildew disease development from airborne transport of
              spores.
            </p>

            <p>
              <a
                href="https://cdm.ipmpipe.org/forecasting/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Cucurbit Downy Mildew | Current Forecast</b>
              </a>
              . Hosted by the Cucurbit Downy Mildew ipmPIPE Forecasting Project,
              this tool provides daily updates of regional and national
              forecasts of cucurbit downy mildew disease development from
              airborne transport of spores.
            </p>

            <p>
              <a
                href="https://blogs.cornell.edu/applevarietydatabase/disease-susceptibility-of-common-apples/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Database of Apple Disease Susceptibility</b>
              </a>
              . Hosted by the Khan Lab at Cornell University, this resource
              provides high quality disease images and detailed information
              about susceptibility levels of apple varieties and common
              cultivars.
            </p>

            <p>
              <a
                href="http://turf.eas.cornell.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Fore Cast</b>
              </a>
              . Hosted by the Northeast Regional Climate Center and Turf Team at
              Cornell University, this tool provides weather-based information
              and maps to manage diseases, irrigation, and weeds in managed turf
              systems in the Northeast.
            </p>

            <p>
              <a
                href="http://www.wheatscab.psu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Fusarium Head Blight Prediction Center</b>
              </a>
              . Hosted by the Center for Environmental Informatics at Penn State
              University, this tool provides regional and national map-based
              risk assessments of Fusarium head blight potential in wheat.
            </p>

            <p>
              <a
                href="http://www.nrcc.cornell.edu/industry/apple/apple.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>NRCC Apple Frost Risk</b>
              </a>
              . Hosted by the Northeast Regional Climate Center at Cornell
              University, this map-based tool provides an assessment of apple
              frost risk in the Northeast United States.
            </p>

            <p>
              <a
                href="http://www.nrcc.cornell.edu/industry/grass/stewart_maps.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Sweet Corn Stewart’s Wilt Map</b>
              </a>
              . Hosted by the Northeast Regional Climate Center at Cornell
              University, this map-based tool provides an assessment of sweet
              corn Stewart’s wilt risk in the Northeast United States, using the
              Iowa State University model.
            </p>

            <hr></hr>
            <h2 id="Other-systems">Other Decision Support Systems</h2>

            <p>
              <small>
                <a href="#top">
                  <b>Top of page</b>
                </a>
              </small>
            </p>

            <p>
              <a
                href="https://extension.umaine.edu/ipm/apple/ag-radar-apple-sites/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Ag-Radar</b>
              </a>
              . The University of Maine Cooperative Extension decision support
              tools for apple diseases, insects and mites, horticulture, and
              weather observations for a subset of locations in New England and
              New York.
            </p>

            <p>
              <a
                href="http://agroclimate.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>AgroClimate</b>
              </a>
              . Based at the University of Florida Extension Program, this site
              has tools, forecasts, state summaries, and resources for managing
              risk in agriculture, focused on the Southeast region of Alabama,
              Florida, Georgia, North Carolina, and South Carolina.
            </p>

            <p>
              <a
                href="https://weather.wsu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>AgWeatherNet</b>
              </a>
              . Washington State University via AgWeatherNet provides farmers,
              gardeners, researchers and policy makers with weather data and
              weather tools to improve agricultural production, efficiency, and
              profitability while minimizing environmental impacts.
            </p>

            <p>
              <a
                href="https://www.climate.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Climate Corp</b>
              </a>
              . The Climate Corporation platform is focused on digital
              agriculture innovations, principally for large-scale agricultural
              production of field crops and has tools for crop performance,
              field imagery, variable rate applications, and fertility
              management.
            </p>

            <p>
              <a
                href="http://climatesmartfarming.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Climate Smart Farming</b>
              </a>
              . Based at Cornell University, this website hosts several climate
              tools for agriculture covering topics such as growing degree days,
              water deficit, cover crop planting, freeze risk, and climate
              change, with a focus on the Northeast region.
            </p>

            <p>
              <a
                href="https://www.enviroweather.msu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Enviroweather</b>
              </a>
              . Michigan State University Extension operates Enviroweather in
              Michigan and select locations in Wisconsin, which provides
              weather-based pest, natural resources, and production management
              tools for fruit, field crops, ornamentals, livestock, trees,
              turfgrass, and vegetables.
            </p>

            <p>
              <a
                href="https://climate.ncsu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>North Carolina Climate Office/Agriculture</b>
              </a>
              . Hosted by the North Carolina State Climate Office at NC State
              University, you’ll find tools for agriculture in NC organized here
              – for irrigation, blueberries, blackberries, loblolly pine, and
              thrips in cotton. Also, find educational resources, data services,
              climate tools and interactive maps here.
            </p>

            <p>
              <a
                href="http://uspest.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>USpest.org</b>
              </a>
              . This site is hosted by the Integrated Plant Protection Center of
              Oregon State University and contains numerous tools and sources of
              weather data of national scope and significance that calculate
              risk in agricultural and horticultural commodities.
            </p>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
            <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of{" "}
              <a href="https://www.onsetcomp.com/corporate/partners/newa">
                Onset
              </a>{" "}
              and{" "}
              <a href="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">
                KestrelMet
              </a>{" "}
              weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support
              for NEWA tools, resources, and weather stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>More Info on NEWA Tools</h2>

            <h3>
              <Link to="/default-biofix-dates">Default Biofix Dates</Link>
            </h3>
            <p>
              Many crop and IPM tools require a biofix date to calculate risk.
              Learn about how NEWA may use a default biofix date to generate
              results.
            </p>

            <h3>
              <Link to="/about-degree-days">About Degree Days</Link>
            </h3>
            <p>
              This page gives background information on how degree days are
              calculated, their importance in biology, and what IPM tools use
              them.
            </p>

            <h3>
              <Link to="/how-newa-handles-weather-data">
                How NEWA Handles Weather Data
              </Link>
            </h3>
            <p>
              Learn about how weather data is kept accurate and up-to-date and
              how variables are uniquely handled to reflect agricultural
              settings.
            </p>

            <hr></hr>

            <h2>Get Help</h2>
            <p>
              Our goal is to provide a positive and productive NEWA experience.
              Visit our Get Help page for more information. Staff are on-hand to
              answer your questions, provide support for NEWA tools and
              resources, or help with your weather station.
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CropPestManagementPage;
