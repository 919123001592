import { useQuery } from 'react-query'
import { fetchGridPointData } from '../fetchData'

export default function useFetchGridPointData(
  gridPoint = { name: null, latitude: null, longitude: null },
  startDate = null,
  endDate = null,
) {
  const currentYear = new Date().getFullYear()
  let sdate = `${currentYear}010100`
  if (startDate) sdate = startDate

  let edate = 'now'
  if (endDate) edate = endDate

  const body = {
    lon: gridPoint.longitude,
    lat: gridPoint.latitude,
    sdate,
    edate,
    tzo: -5
  }

  const { data, isLoading, isError } = useQuery(
    `${body.sdate}-${body.edate} (${gridPoint.latitude},${gridPoint.longitude})`,
    () => fetchGridPointData(body),
    {
      refetchOnWindowFocus: false,
      enabled: gridPoint.latitude !== null && gridPoint.longitude !== null,
      refetchOnMount: false,
      cacheTime: 900000, // data remains in memory for 15 minutes
    },
  )

  return { data, isLoading, isError }
}
