import * as React from 'react'
import { useCallback } from 'react'
import MapGL, {
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
  Marker
} from 'react-map-gl'

const layerStyle = {
  id: 'newa-grid-points',
  type: 'symbol',
  source: 'composite',
  layout: {
    'icon-image': ['case', ['==', ['get', 'isFavorite'], true], 'none', 'pin'],
    'text-field': ['step', ['zoom'], '', 8, ['to-string', ['get', 'name']]],
    'text-size': 11,
    'text-anchor': 'bottom',
    'text-offset': [0, -1.0],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 1,
  },
}

export default function MapGridData({ gridPoint, setGridPoint, favoriteGridPoints, geoJSON, addFavoriteGridPoint=() => null, tabNum=0 }) {
  const mapRef = React.useRef()

  const onClick = useCallback(
    (event) => {
      const precision = 100000;
      const newGP = {
        name: 'Custom',
        longitude: Math.round(event.lngLat.lng * precision) / precision,
        latitude: Math.round(event.lngLat.lat * precision) / precision
      }
      setGridPoint(newGP)
      addFavoriteGridPoint(newGP);
    },
    [setGridPoint],
  )

  const handleMarkerClick = useCallback(
    (event, gp) => {
      event.originalEvent.stopPropagation();
      setGridPoint(gp);
    },
    [setGridPoint]
  );

  React.useEffect(() => {
    if (gridPoint === null) return
    mapRef.current?.flyTo({
      center: [gridPoint.longitude, gridPoint.latitude],
      duration: 2000,
      zoom: 9,
    })
  }, [gridPoint])

  React.useEffect(() => {
    if (mapRef.current?.loaded()) {
      mapRef.current?.resize()
    }
  }, [tabNum])

  return (
    <MapGL
      id="newaGridPointMap"
      ref={mapRef}
      initialViewState={{
        longitude: gridPoint.longitude,
        latitude: gridPoint.latitude,
        zoom: 9,
      }}
      width='100%'
      height='100%'
      mapStyle={'mapbox://styles/nysipm/cl0gkib5s00g715p2nv3rw5z3'}
      mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
      clickTolerance={3}
      dragRotate={false}
      interactiveLayerIds={[layerStyle.id]}
      onClick={onClick}
      cursor='pointer'
    >
      <Source id="newa-grid-points" type="geojson" data={geoJSON}>
        <Layer {...layerStyle}></Layer>
      </Source>

      {favoriteGridPoints.map((gp, i) => {
        return <Marker
          key={gp.name + i}
          latitude={gp.latitude}
          longitude={gp.longitude}
          style={{ 'cursor': 'pointer' }}
          offset={[0,1]}
          onClick={(e) => handleMarkerClick(e, gp)}
        >
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-7 h-7 text-orange-400 star"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        </Marker>
      })}

      {/* Items on Map */}
      <NavigationControl
        position="top-right"
        showCompass={false}
      ></NavigationControl>

      <ScaleControl unit="imperial" position="bottom-right" />
    </MapGL>
  )
}