import React from "react";

const Footer = () => {
    return (
        <div>
            <footer className="px-4 ml-4 mr-4 mt-2 mb-6 items-center justify-center max-w-full mx-auto text-gray-700 no-underline">
                <p className="text-center" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <small>
                    <strong>Disclaimer.</strong> The information provided by NEWA is intended as a reference and should <strong><em>never</em></strong> be used as a standalone product for making critical decisions. Users are advised to consult multiple sources of information, including their own experience and professional guidance from extension experts, consultants, or other qualified professionals. Always verify NEWA guidelines in the context of your specific situation before taking any action.
                    </small>
                </p>
            </footer>
        </div>
    );
};

export default Footer;
