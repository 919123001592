import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./seedcorn-maggot.json";
import Acknowledgments from "./_acknowledgements";
import LandscapeSummary from "./_landscapeSummary";
import LandUseComposition from "./_landUseComposition";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElementsArr = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

const fields = [
  { label: "Field Corn", value: "fieldCorn" },
  { label: "Sweet Corn", value: "sweetCorn" },
  { label: "Snap Beans", value: "snapBeans" },
  { label: "Soy Beans", value: "soyBeans" },
];

export default function SeedcornMaggot() {
  const h1Text = modelData.title;
  const { elements, seasonEnd } = modelData;
  const { moreInfo } = elements;
  const { isAuthenticated, user } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();

  const { dateOfInterest } = React.useContext(GlobalStateContext);

  // If the user has already selected a station but the station's state isn't NY, then don't use for this model
  // Note that this doesn't prevent the user from now selecting a station
  const validatedStation = React.useMemo(() => {
    if (station?.state !== "NY") {
      return null;
    }
    return station;
  }, [station]);

  let cldYear = 2020;
  if (dateOfInterest) {
    if (dateOfInterest.year <= cldYear) cldYear = dateOfInterest.year;
  }

  let cldData;

  let filteredStationList = [...stationList];
  let filteredFavoriteStations = [...favoriteStations];
  if (stationList.length !== 0) {
    filteredStationList = stationList.filter((d) => d.label === "New York");
    filteredFavoriteStations = favoriteStations.filter(
      (d) => d.label === "New York"
    );
  }

  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);

  let endDate = edate;
  if (edate !== "now") {
    const endDateBase = edate.slice(0, 8);
    endDate = `${endDateBase}23`;
  }
  const { isLoading, data } = useFetchStationData(validatedStation, sdate, endDate);

  const [uInput] = React.useState(true);
  const [uInput2] = React.useState(fields[0]);

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(modelData, data, dateOfInterest, uInput, uInput2);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showResultsTable2, setShowResultsTable2] = React.useState(false);
  const [showResultsChart, setShowResultsChart] = React.useState(false);
  const isVisible = validatedStation && mData && !isLoading;

  let visibleElements = visibleElementsArr;
  if (dateOfInterest.year !== cldYear) {
    const visibleElementsCopy = [...visibleElements].filter(
      (d) =>
        d.title !== "Landscape Summary" && d.title !== "Land Use Composition"
    );
    visibleElements = visibleElementsCopy;
  }

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? filteredFavoriteStations : filteredStationList}
      station={validatedStation}
      setStation={setStation}
      allStations={filteredStationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      titleSize={"text-lg lg:text-2xl"}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo moreInfo={moreInfo}/>}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsTable2={showResultsTable2}
      setShowResultsTable2={setShowResultsTable2}
      showResultsChart={showResultsChart}
      setShowResultsChart={setShowResultsChart}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={validatedStation}
            tableData={mData.resultsTable}
            csvData={mData.csvData}
          ></ResultsTable>
        </div>
      )}

      {false &&
        isModelInSeason &&
        isVisible &&
        dateOfInterest.year !== cldYear && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <div className="py-3 bg-white rounded-lg shadow-lg">
              <div className="flex items-center justify-center px-4 py-3 text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h2 className="">
                  Landscape composition is currently only available for{" "}
                  <span className="font-semibold">{cldYear}</span>.
                </h2>
              </div>
            </div>
          </div>
        )}

      {/* Landscape Summary Table */}
      {isModelInSeason &&
        isVisible &&
        showResultsTable2 &&
        cldData &&
        dateOfInterest.year === cldYear && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <LandscapeSummary
              tableData={cldData}
              csvData={[]}
            ></LandscapeSummary>
          </div>
        )}

      {isModelInSeason &&
        isVisible &&
        showResultsChart &&
        cldData &&
        dateOfInterest.year === cldYear && (
          <div className="mb-16 sm:mb-18 md:mb-20">
            <h2 className="mb-3 text-xl font-semibold text-gray-600 md:text-2xl md:mb-6">
              Land Use Composition
            </h2>
            <LandUseComposition data={cldData}></LandUseComposition>
          </div>
        )}
    </ModelLayout>
  );
}
