import React, { useState, useEffect } from 'react';

import MapGridData from './mapGridData';

function GriddedLocationSelectionInput({ label, name, value, handleChange, isError, errorMessage, type, inputProps={}, labelStyle={} }) {
  const id = name + 'Input'

  return (
    <div className="input-container">
      <label style={labelStyle} htmlFor={id}>{label}</label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        className="custom-input"
        style={{ padding: '6px 10px' }}
        {...inputProps}
      />
      {isError && (
        <p className="error-message">
          <mark>
            <small>{errorMessage}</small>
          </mark>
        </p>
      )}
    </div>
  );
}

export default function GriddedLocationSelection({ gridPoint, setGridPoint, geoJSON, favoriteGridPoints }) {
  const [manualCoordinateInput, setManualCoordinateInput] = useState({
    latitude: '',
    invalidLatitude: false,
    longitude: '',
    invalidLongitude: false,
  });

  useEffect(() => {
    setManualCoordinateInput({
      ...gridPoint,
      invalidLatitude: false,
      invalidLongitude: false,
    }) 
  }, [gridPoint]);

  const handleManualInputChange = (e) => {
    if (e && e.target && (e.target.value || e.target.value === '')) {
      const { name, value } = e.target;
      setManualCoordinateInput((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.error("Invalid event object or missing properties:", e);
    }
  };

  const validateCoordinate = (coordNum, coordType) => {
    const validCoordinate = /^-?\d{2,3}\.?\d*$/;
    const usBbox = {
      longitude: { min: -125.2, max: -66.5 },
      latitude: { min: 24.8, max: 49.6 }
    };

    return validCoordinate.test(coordNum) && !isNaN(coordNum) && coordNum >= usBbox[coordType].min && coordNum <= usBbox[coordType].max
  }

  const handleSubmit = () => {
    const { latitude, longitude } = manualCoordinateInput;

    const precision = 100000
    const latitudeNumber = Math.round(parseFloat(latitude) * precision) / precision;
    const isLatitudeValid = validateCoordinate(latitudeNumber, 'latitude');
    
    const longitudeNumber = Math.round(parseFloat(longitude) * precision) / precision;
    const isLongitudeValid = validateCoordinate(longitudeNumber, 'longitude');
    
    setManualCoordinateInput({
      latitude: latitudeNumber, 
      longitude: longitudeNumber,
      invalidLatitude: !isLatitudeValid,
      invalidLongitude: !isLongitudeValid,
    });
    
    if (isLatitudeValid && isLongitudeValid) {
      const isExisting = favoriteGridPoints.find(f => f.latitude === latitudeNumber && f.longitude === longitudeNumber);
      if (isExisting) {
        setGridPoint(isExisting);
      } else {
        setGridPoint({ name: "Custom", latitude: latitudeNumber, longitude: longitudeNumber });
      }
    }
  };

  const isCurrentGridPoint = gridPoint && gridPoint.latitude === manualCoordinateInput.latitude && gridPoint.longitude === manualCoordinateInput.longitude;
  const disabledBtnStyle = { backgroundColor: '#c9c9c9', color: 'rgb(80,80,80)', cursor: 'default', width: '160px' };
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <h2 style={{ marginTop: '20px' }}>Grid Data is Estimated</h2>
        
        <p style={{ padding: 0, lineHeight: 1.25 }}>
          Enter latitude and longitude or click on the map for any location in the
          continental United States. Data provided by{" "}
          <a href="https://nrcc.cornell.edu" target="blank">
            Northeast Regional Climate Center
          </a>
          .
        </p>

        <div>
          <h2 style={{ marginTop: '12px' }}>Coordinates (Decimal Degrees)</h2>

          <div style={{ display: 'flex', gap: '4px' }}>
            <GriddedLocationSelectionInput
              label='Latitude'
              name='latitude'
              value={manualCoordinateInput.latitude}
              handleChange={handleManualInputChange}
              isError={manualCoordinateInput.invalidLatitude}
              errorMessage='Please provide a positive latitude between 24.8 and 49.6'
              type='number'
              inputProps={{ step: 0.001 }}
              labelStyle={{ fontSize: '14px' }}
            />

            <GriddedLocationSelectionInput
              label='Longitude'
              name='longitude'
              value={manualCoordinateInput.longitude}
              handleChange={handleManualInputChange}
              isError={manualCoordinateInput.invalidLongitude}
              errorMessage='Please provide a negative longitude between -125.2 and -66.5'
              type='number'
              inputProps={{ step: 0.001 }}
              labelStyle={{ fontSize: '14px' }}
            />
          </div>
        </div>

        <div className="button-container">
          <button className="blue-button" style={isCurrentGridPoint ? disabledBtnStyle : { width: '160px' }} onClick={handleSubmit} disabled={isCurrentGridPoint}>Change Location</button>
        </div>

        <div className="relative border border-gray-300 rounded-md w-full" style={{ height: '260px' }}>
          <MapGridData
            gridPoint={gridPoint}
            setGridPoint={setGridPoint}
            favoriteGridPoints={favoriteGridPoints}
            geoJSON={geoJSON}
          />
        </div>

      </div>
    </div>
  );
}