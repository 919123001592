import React from "react";
import Header from "./grapeColdHardinessHeader";
import SubHeader from "./grapeColdHardinessSubHeader";
import Footer from "./grapeColdHardinessFooter";

export default function GrapeColdHardinessModelLayout({
  h1Text,
  station,
  children,
}) {
  return (
    <>
      <Header></Header>
      <SubHeader h1Text={h1Text} station={station}></SubHeader>
      <div>
        <main>{children}</main>
      </div>
      <Footer></Footer>
    </>
  );
}
