import React, { useEffect, useState } from "react"; // Basic react components
import { useAuth0 } from "../../context/react-auth0-spa"; // Auth0 authentication + unique user ID
import DatePicker from "react-datepicker"; // Calendar datetime choose
import { Carousel } from "react-responsive-carousel"; //Carousel for daily forecast information
import DailyRiskCardComponent from "../../components/models/grapeColdHardiness/grapeColdHardinessCardContent";
import GrapeColdHardinessModelLayout from "../../components/models/grapeColdHardiness/grapeColdHardinessModelLayout"; //
import StationsListing from "../../components/stationsListingV3"; // Station dropdown listing
import Map from "../../components/mapVersion2";
import GriddedLocationSelection from "../../components/griddedLocationSelection"; // for using gridded data instead of station data

import useStations from "../../utils/hooks/useStations"; // What is this?????
import useGridPoints from "../../utils/hooks/useGridPoints";

import ModelMetadata from "../../components/models/grapeColdHardiness/grapeColdHardinessMetadata.json";
import UseGetLastDownloads from "../../components/models/grapeColdHardiness/grapeColdHardinessFetchData";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useFetchGridPointData from "../../utils/hooks/useFetchGridPointData";

import { calculateNYUS1 } from "../../components/models/grapeColdHardiness/grapeColdHardinessModelNYUS1";
//import { modelCalculationsNYUS2 } from '../components/grapeColdHardinessModelNYUS2';
import { calculateWIUS1Model } from "../../components/models/grapeColdHardiness/grapeColdHardinessModelWIUS1";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel CSS stylesheet
import "../../components/models/grapeColdHardiness/grapeColdHardinessStylesheet.css"; // Model CSS

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Legend,
} from "recharts";

// UNUSED IMPORTS...
// import GlobalStateContext from '../../context/globalStateContext';
// import React, { useContext, Component } from 'react';

///////////////////////////////////////////////////////////////////////////////////////////////////
// No longer needed, but required by the existing <ModelLayout>. Keep but ignore...
///////////////////////////////////////////////////////////////////////////////////////////////////
const visibleElements = Object.entries(ModelMetadata.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

const h1Text = "Grape Cold Hardiness Risk Assessment";
///////////////////////////////////////////////////////////////////////////////////////////////////
// BUILD GRAPE COLD HARDINESS PAGE
///////////////////////////////////////////////////////////////////////////////////////////////////
export default function GrapeColdHardiness() {
  const { isAuthenticated, user } = useAuth0();

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // LOCATION CONFIGURATION
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedDataSource, setSelectedDataSource] = useState("NEWA"); // Data source selection
  const { gridPoint, setGridPoint, gridPointGeoJSON, favoriteGridPoints } =
    useGridPoints();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const [sid, setSid] = useState("gen newa");
  useEffect(() => {
    if (station) {
      setSid(station.id);
    } else {
      setSid("gen newa");
    }
  }, [station]); // Listen for changes in the station and update as needed
  const handleStationSelect = (selectedStation) => {
    setStation(selectedStation);
  };

  const handleDataSourceChange = (dataSource) => {
    setSelectedDataSource(dataSource);
  }; // Update data source

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // MODEL CONFIGURATION
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [selectedUnit, setSelectedUnit] = useState("F"); // Temperature unit
  const [currentDate, setCurrentDate] = useState(new Date());
  const currentYear = currentDate.getFullYear();
  const defaultStartDate = new Date(currentYear, 0, 1); // Month is 0-based (0 for January)
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [trimmedSummaryData, setTrimmedSummaryData] = useState([]);

  function getDefaultStartDate(currentDate) {
    // Get the current year
    const currentYear = currentDate.getFullYear();

    // Check if the current month is before July (0-indexed)
    if (currentDate.getMonth() < 6) {
      // Months are 0-indexed, so July is index 6
      // If before July, default start date is July 1 of the previous year
      return new Date(currentYear - 1, 6, 1);
    } else {
      // If September or later, default start date is September 1 of the current year
      return new Date(currentYear, 6, 1);
    }
  }

  useEffect(() => {
    // Update default start date whenever current date changes
    setStartDate(getDefaultStartDate(currentDate));
  }, [currentDate]);

  const [selectedCultivar, setSelectedCultivar] = useState(null); // Cultivar selection
  const [selectedModel, setSelectedModel] = useState(null); // Model selection

  useEffect(() => {
    // Clear data when the model changes
    setTrimmedSummaryData([]);
  }, [currentDate, selectedModel]);

  const handleUnitChange = (newUnit) => {
    setSelectedUnit(newUnit);
  };

  const handleChangeCultivar = (event) => {
    const selectedValue = event.target.value;
    setSelectedCultivar(selectedValue);
    const cultivar = ModelMetadata.cultivars.find(
      (cultivar) => cultivar.cultivarName === selectedValue
    );
    if (cultivar) {
      const selectedModel = cultivar.modelHelperFunction;
      setSelectedModel(selectedModel);
      console.log("Selected Model Changed to:",selectedModel);
    }
  };

  const stationDataStatus = UseGetLastDownloads(
    station,
    startDate,
    currentDate
  );

  // console.log("selected model", selectedModel);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // CAROUSEL CONFIGURATION
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const [startIndex, setStartIndex] = useState(2); // Carousel

  // Save the selected starting index to local storage
  useEffect(() => {
    localStorage.setItem("carouselStartIndex", startIndex.toString());
  }, [startIndex]);

  // Retrieve the selected starting index from local storage on component mount
  useEffect(() => {
    const storedStartIndex = localStorage.getItem("carouselStartIndex");
    if (storedStartIndex) {
      setStartIndex(parseInt(storedStartIndex, 10));
    }
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // FETCH DAILY DATA
  /////////////////////////////////////////////////////////////////////////////////////////////////
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}00`;
  }

  function getFormattedDate(currentDate) {
    const today = new Date();
    //console.log("TODAY:",today)
    
    // Add 6 days to currentDate
    const currentDatePlus6 = new Date(currentDate);
    currentDatePlus6.setDate(currentDate.getDate() + 6);
    //console.log("CURRENT DATE PLUS 6:",currentDatePlus6)
  
    // Check if currentDate + 6 is greater than or equal to today
    if (currentDatePlus6 <= today) {
      return formatDateToYYYYMMDD(currentDatePlus6);
    }
  
    // Otherwise, return "now"
    return "now";
  }

  let sdate = `${currentYear}010100`;
  if (startDate) sdate = formatDateToYYYYMMDD(startDate);

  const edate = getFormattedDate(currentDate);
  //console.log("EDATE:",edate)

  const { isLoading: isStationLoading, data: stationData } =
    useFetchStationData(station, sdate, edate);
  const { isLoading: isGridPointLoading, data: gridPointData } =
    useFetchGridPointData(gridPoint, sdate, edate);

  const data = selectedDataSource === "NEWA" ? stationData : gridPointData;
  const isLoading =
    selectedDataSource === "NEWA" ? isStationLoading : isGridPointLoading;

  // console.log("DATA", isLoading, data);

  let dailyWeatherData = null;
  if (data) {
    dailyWeatherData = data.dailyData;
  }
  let hourlyWeatherData = null;
  if (data) {
    hourlyWeatherData = data.hourlyData;
  }
  let hourlyForecastData = null;
  if (data) {
    hourlyForecastData = data.hourlyFcstData;
  }
  let combinedHourlyData = null;
  if (hourlyWeatherData && hourlyForecastData) {
    combinedHourlyData = hourlyWeatherData.concat(hourlyForecastData);
  }

  useEffect(() => {
    const calculate = async () => {
      if (!selectedCultivar || !selectedModel) {
        return; // Ensure all necessary data is available before calculating
      }

      // Ensure all data is available
      if (!dailyWeatherData || !combinedHourlyData) {
        console.log("Data not fully loaded yet.");
        return;
      }

      if (selectedModel === "grapeColdHardinessNYUS1") {
        try {
          const result = await calculateNYUS1(
            dailyWeatherData,
            combinedHourlyData,
            selectedCultivar
          );
          const calculations = result[0]?.NYUS1calculations; // Using optional chaining to handle possible undefined result[0]
          if (calculations) {
            console.log("calculations:", calculations);
          } else {
            console.error("NYUS1 calculations not found in result[0]");
          }
          let summaryData = [];
          for (let i = 0; i < calculations.length; i++) {
            const date = calculations[i].date;
            const isForecast = dailyWeatherData[i].isForecast;
            const lt50_celsius =
              Math.round(calculations[i].Kovaleski_8_CH * 10) / 10;
            const maxT_celsius =
              Math.round(calculations[i].T_air_max * 10) / 10;
            const minT_celsius =
              Math.round(calculations[i].T_air_min * 10) / 10;
            const lt50_fahrenheit =
              Math.round((lt50_celsius * (9 / 5) + 32) * 10) / 10;
            const maxT_fahrenheit =
              Math.round((maxT_celsius * (9 / 5) + 32) * 10) / 10;
            const minT_fahrenheit =
              Math.round((minT_celsius * (9 / 5) + 32) * 10) / 10;

            const record = {
              date,
              isForecast,
              lt50_celsius,
              maxT_celsius,
              minT_celsius,
              lt50_fahrenheit,
              maxT_fahrenheit,
              minT_fahrenheit,
            };

            summaryData.push(record);
          }
          //console.log("summary data NYUS1:", summaryData);
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;

          const isDateInRange = (dateString) => {
            const date = new Date(dateString);
            const month = date.getMonth() + 1; // Months are 0-indexed
            const day = date.getDate();

            // Check if the date is between June 15 and September 1
            return (
              (month === 6 && day >= 15) ||
              month === 7 ||
              (month === 8 && day < 1)
            );
          };

          // Filter out records where the date falls between June 15 and September 1
          const filteredSummaryData = summaryData.filter(
            (record) => !isDateInRange(record.date)
          );

          // Update the state with the filtered data
          setTrimmedSummaryData(filteredSummaryData);
        } catch (error) {
          console.error("Error calculating NYUS1:", error);
        }
      } else if (selectedModel === "grapeColdHardinessWIUS1") {
        try {
          const result = await calculateWIUS1Model(
            dailyWeatherData,
            combinedHourlyData,
            selectedCultivar
          );
          //console.log("WIUS1 Result:",result);
          const calculations = result.WIUS1Calculations; // Using optional chaining to handle possible undefined result[0]
          if (calculations) {
            console.log("calculations:", calculations);
          } else {
            console.error("WIUS1 calculations not found in result[0]");
          }
          let summaryData = [];
          for (let i = 0; i < calculations.length; i++) {
            const date = calculations[i].date;
            const isForecast = dailyWeatherData[i].isForecast;
            const lt50_celsius = Math.round(calculations[i].Hc * 10) / 10;;
            const maxT_celsius = Math.round(calculations[i].tempMaxC * 10) / 10;;
            const minT_celsius = Math.round(calculations[i].tempMinC * 10) / 10;;
            const lt50_fahrenheit =
              Math.round((lt50_celsius * (9 / 5) + 32) * 10) / 10;
            const maxT_fahrenheit =
              Math.round((maxT_celsius * (9 / 5) + 32) * 10) / 10;
            const minT_fahrenheit =
              Math.round((minT_celsius * (9 / 5) + 32) * 10) / 10;

            const record = {
              date,
              isForecast,
              lt50_celsius,
              maxT_celsius,
              minT_celsius,
              lt50_fahrenheit,
              maxT_fahrenheit,
              minT_fahrenheit,
            };

            summaryData.push(record);
          }
          //console.log("summary data WIUS1:", summaryData);
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const day = String(currentDate.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;

          const isDateInRange = (dateString) => {
            const date = new Date(dateString);
            const month = date.getMonth() + 1; // Months are 0-indexed
            const day = date.getDate();

            // Check if the date is between June 15 and September 1
            return (
              (month === 6 && day >= 15) ||
              month === 7 ||
              (month === 8 && day < 1)
            );
          };

          // Filter out records where the date falls between June 15 and September 1
          const filteredSummaryData = summaryData.filter(
            (record) => !isDateInRange(record.date)
          );

          // Update the state with the filtered data
          setTrimmedSummaryData(filteredSummaryData);
        } catch (error) {
          console.error("Error calculating WIUS1:", error);
        }
      }
    };
    calculate();
  }, [
    currentDate,
    startDate,
    selectedCultivar,
    selectedModel,
    selectedDataSource,
    dailyWeatherData,
  ]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // MISCELLANEOUS
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const lastDownload =
    stationDataStatus.getLatestTimestampResult.lastRecordTimestampFormatted;
  const mapCustomHeight = "275px";

  const lt50_data = selectedUnit === "F" ? "lt50_fahrenheit" : "lt50_celsius";
  const t_min_data = selectedUnit === "F" ? "minT_fahrenheit" : "minT_celsius";
  const t_max_data = selectedUnit === "F" ? "maxT_fahrenheit" : "maxT_celsius";
  const lt50_label = selectedUnit === "F" ? "LT50 (°F)" : "LT50 (°C)";
  const t_min_label = selectedUnit === "F" ? "Min T (°F)" : "Min T (°C)";
  const t_max_label = selectedUnit === "F" ? "Max T (°F)" : "Max T (°C)";
  const y_axis_label =
    selectedUnit === "F" ? "Temperature (°F)" : "Temperature (°C)";

  let yAxisDomain;
  if (selectedUnit === "F") {
    yAxisDomain = [-30, 100]; // Example Fahrenheit range
  } else {
    yAxisDomain = [-35, 40]; // Example Celsius range
  }

  let tickCount;
  if (selectedUnit === "F") {
    tickCount = 8; // Example tick count for Fahrenheit
  } else {
    tickCount = 6; // Example tick count for Celsius
  }

  const handleDownloadCSV = () => {
    // Convert trimmedSummaryData to CSV format
    const csv = convertToCSV(trimmedSummaryData);
    // Create a Blob object from the CSV data
    const blob = new Blob([csv], { type: "text/csv" });
    // Create a temporary anchor element
    const a = document.createElement("a");
    // Set the download attribute and filename
    a.download = station.name + "-" + selectedCultivar + ".csv";
    // Create a URL for the Blob object
    a.href = window.URL.createObjectURL(blob);
    // Append the anchor element to the body
    document.body.appendChild(a);
    // Trigger a click event on the anchor element
    a.click();
    // Remove the anchor element from the body
    document.body.removeChild(a);
  };

  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    // Define the headers for the CSV file
    const headers = [
      "date",
      "isForecast",
      "lt50_celsius",
      "lt50_fahrenheit",
      "maxT_celsius",
      "maxT_fahrenheit",
      "minT_celsius",
      "minT_fahrenheit",
    ];

    // Map the data to CSV rows
    const rows = data.map((item) => {
      return headers
        .map((header) => {
          // For date values, format them properly
          if (header === "date") {
            return '"' + item[header] + '"';
          }
          // For boolean values, convert them to "true" or "false"
          else if (typeof item[header] === "boolean") {
            return item[header] ? "true" : "false";
          }
          // For other values, simply return them
          else {
            return item[header];
          }
        })
        .join(",");
    });

    // Combine headers and rows
    const csv = [headers.join(","), ...rows].join("\n");

    return csv;
  };

  const [expandedCards, setExpandedCards] = useState({
    riskDetails: true,
    historicalResults: true,
    dataSourceDetails: true,
    modelDetails: true,
  });

  const toggleExpand = (card) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [card]: !prevState[card],
    }));
  };

  //console.log("Trimmed data summary", trimmedSummaryData);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  // RENDER OUTPUT
  /////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <GrapeColdHardinessModelLayout
      isAuthenticated={isAuthenticated}
      modelData={ModelMetadata}
      visibleElements={visibleElements}
      stationList={user ? favoriteStations : stationList}
      isLoading={isLoading}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      customHeight={mapCustomHeight}
      h1Text={h1Text}
    >
      <div id="app-container" className="grape-container">
        <div id="data-source-details" className="card-small">
          <h1 onClick={() => toggleExpand("dataSourceDetails")}>
            <span>Location details</span>
            <span>{expandedCards.dataSourceDetails ? "▼" : "►"}</span>
          </h1>
          {expandedCards.dataSourceDetails && (
            <div>
              <div className="button-container">
                <br />
                <label>Choose a Data Source</label>
                <div className="toggle-button">
                  <button
                    id="sourceNewa"
                    className={`toggle-button-option ${
                      selectedDataSource === "NEWA" ? "active" : ""
                    }`}
                    onClick={() => handleDataSourceChange("NEWA")}
                  >
                    NEWA Station
                  </button>
                  <button
                    id="sourceGrid"
                    className={`toggle-button-option ${
                      selectedDataSource === "GRID" ? "active" : ""
                    }`}
                    onClick={() => handleDataSourceChange("GRID")}
                  >
                    Grid Data
                  </button>
                </div>
              </div>
              <br />
              <div>
                {selectedDataSource === "NEWA" ? (
                  <div>
                    <StationsListing
                      id="stationsListing"
                      options={stationList}
                      value={station}
                      setValue={handleStationSelect}
                    ></StationsListing>
                    <br />
                    <div>
                      <Map
                        station={station}
                        setStation={handleStationSelect}
                        allStations={stationList}
                        geoJSON={geoJSON}
                        customHeight={mapCustomHeight}
                      ></Map>
                    </div>
                    <div>
                      {station === null || station === undefined ? (
                        <h2>Select a station...</h2>
                      ) : (
                        <>
                          <h2>
                            {station.name}, {station.state}
                          </h2>
                          <p>
                            Courtesy of{" "}
                            <a href="{station.affiliationUrl}" target="blank">
                              {station.affiliation}
                            </a>
                            <br />
                            Data last received <strong>{lastDownload}</strong>
                            <br />
                            In service since {station.startYear}
                            <br />
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <GriddedLocationSelection
                    gridPoint={gridPoint}
                    setGridPoint={setGridPoint}
                    geoJSON={gridPointGeoJSON}
                    favoriteGridPoints={favoriteGridPoints}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div id="model-details" className="card-small">
          <h1 onClick={() => toggleExpand("modelDetails")}>
            <span>Model details</span>
            <span>{expandedCards.modelDetails ? "▼" : "►"}</span>
          </h1>
          {expandedCards.modelDetails && (
            <div className="input-container">
              <div className="button-container">
                <br />
                <label>Select a Unit of Temperature</label>
                <div className="toggle-button">
                  <button
                    id="unitF"
                    className={`toggle-button-option ${
                      selectedUnit === "F" ? "active" : ""
                    }`}
                    onClick={() => handleUnitChange("F")}
                  >
                    Fahrenheit (°F)
                  </button>
                  <button
                    id="unitC"
                    className={`toggle-button-option ${
                      selectedUnit === "C" ? "active" : ""
                    }`}
                    onClick={() => handleUnitChange("C")}
                  >
                    Celsius (°C)
                  </button>
                </div>
                <br />
              </div>
              <br />
              <br />
              <label htmlFor="">Choose the Current Date</label>
              <DatePicker
                id="datePicker"
                selected={currentDate}
                onChange={(date) => setCurrentDate(date)}
              ></DatePicker>
              <br />
              <br />
              <label>Model Start Date</label>
              <p>
                <mark>{startDate.toLocaleDateString()}</mark>
              </p>
              <br />
              <br />
              <label htmlFor="cultivarSelect">Select a Grape Cultivar</label>
              <select
                id="cultivarSelect"
                onChange={handleChangeCultivar}
                value={selectedCultivar || ""}
                style={{ width: "100%" }}
              >
                <option value="">Select...</option>
                {ModelMetadata.cultivars
                  .sort((a, b) => a.cultivarName.localeCompare(b.cultivarName)) // Sort the options alphabetically
                  .map((cultivar, index) => (
                    <option key={index} value={cultivar.cultivarName}>
                      {cultivar.cultivarName}
                    </option>
                  ))}
              </select>
              {!selectedCultivar && (
                <div>
                  <p>
                    <em>Select a cultivar to view model information</em>
                  </p>
                </div>
              )}
              {selectedCultivar && (
                <div>
                  <p>
                    <em>
                      {
                        ModelMetadata.cultivars.find(
                          (cultivar) =>
                            cultivar.cultivarName === selectedCultivar
                        )?.displayModelName
                      }{" "}
                      is used for this cultivar
                    </em>
                  </p>
                </div>
              )}
              <div className="button-container">
                <br />
                <br />
                <label>Download this dataset</label>
                <div className="toggle-button"></div>
                <button
                  className="toggle-button-option active"
                  onClick={handleDownloadCSV}
                >
                  Download a CSV
                </button>
              </div>
              <br />
            </div>
          )}
        </div>
        <div id="graph-visualization" className="card-small">
          <h1 onClick={() => toggleExpand("historicalResults")}>
            <span>Visual results</span>
            <span>{expandedCards.historicalResults ? "▼" : "►"}</span>
          </h1>
          {expandedCards.historicalResults && trimmedSummaryData !== null && (
            <LineChart
              width={350}
              height={600}
              data={trimmedSummaryData}
              margin={{ top: 40, right: 10, left: 10, bottom: 40 }} // Increased top margin for centering
            >
              <Legend
                align="center"
                verticalAlign="bottom"
                height={36}
                layout="horizontal"
                wrapperStyle={{
                  position: "relative",
                  marginLeft: "auto",
                  marginRight: "auto", // Center the legend
                }}
              />
              <XAxis
                dataKey="date"
                tickLine={true}
                tickFormatter={(date) =>
                  new Date(date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  })
                }
                tick={{
                  fontSize: 10,
                  angle: -45,
                  dy: 5,
                  textAnchor: "end",
                }}
                interval={Math.ceil(trimmedSummaryData.length / 10)}
              />
              <YAxis
                tick={{ fontSize: 12 }}
                domain={yAxisDomain}
                label={{
                  value: y_axis_label,
                  angle: -90,
                  position: "outsideRight",
                  fill: "#000000",
                  fontSize: 14,
                  dx: -10,
                }}
                tickCount={tickCount}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey={lt50_data}
                name={lt50_label}
                stroke="#ff0000"
                strokeWidth={1}
                strokeDasharray="3 1"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey={t_min_data}
                name={t_min_label}
                stroke="#333333"
                dot={false}
              />
              <Line
                type="monotone"
                dataKey={t_max_data}
                name={t_max_label}
                stroke="#808080"
                strokeDasharray="2 2"
                dot={false}
              />
              <ReferenceLine
                x={trimmedSummaryData[trimmedSummaryData.length - 6]?.date}
                stroke="#4169E1"
                strokeDasharray="7 7"
                label={{
                  value:
                    trimmedSummaryData[trimmedSummaryData.length - 6]?.date,
                  position: "insideBottomRight",
                  offset: 5,
                  fill: "#000000",
                  fontSize: 12,
                }}
              />
            </LineChart>
          )}
        </div>
        <div id="freeze-risk-forecast" className="card-small">
          <h1 onClick={() => toggleExpand("riskDetails")}>
            <span>Freeze risk assessment </span>
            <span>{expandedCards.riskDetails ? "▼" : "►"}</span>
          </h1>
          <br />
          <h2>
            <em>
              <strong>
                {selectedCultivar ? selectedCultivar : "Select a cultivar..."}
              </strong>
            </em>
            <small>
              {
                ModelMetadata.cultivars.find(
                  (cultivar) => cultivar.cultivarName === selectedCultivar
                )?.displayModelName
              }
            </small>
          </h2>
          {expandedCards.riskDetails && (
            <Carousel
              showArrows={true}
              infiniteLoop={true}
              showStatus={false}
              showIndicators={true}
              selectedItem={startIndex}
              showThumbs={false}
            >
              {/* Content for the carousel slides */}
              <div id="summaryData_length_minus_8" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={7}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_7" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={6}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_5" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={5}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_5" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={4}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_4" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={3}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_3" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={2}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_2" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={1}
                  selectedUnit={selectedUnit}
                />
              </div>
              <div id="summaryData_length_minus_1" className="slide-card">
                <DailyRiskCardComponent
                  data={trimmedSummaryData}
                  indexOffset={0}
                  selectedUnit={selectedUnit}
                />
              </div>
              {/* Add more carousel slides as needed */}
            </Carousel>
          )}
        </div>
      </div>
    </GrapeColdHardinessModelLayout>
  );
}
