import React, { useState } from 'react'
import { Dialog } from '@reach/dialog'
import '@reach/dialog/styles.css'
import VisuallyHidden from '@reach/visually-hidden'
import Card from '../components/card'
import MapGridData from './mapGridData'
import useGridPoints from '../utils/hooks/useGridPoints'

export default function FavoriteGridPoints({ tabNum }) {
  const [rename, setRename] = useState(null)
  const close = () => setRename(null)
  
  const { gridPoint, setGridPoint, gridPointGeoJSON, favoriteGridPoints, addFavoriteGridPoint, removeFavoriteGridPoint, editFavoriteGridPoint } = useGridPoints();

  return (
    <div className="mx-1 mb-10">
      <section className="mt-3">
        <div className="container mx-auto">
          <h2 className="text-2xl font-semibold text-center lg:text-3xl">
            Your Favorite Grid Points
          </h2>
          <h1 style={{ textAlign: 'center' }}><mark>Grid points are only available for the <a href="https://newa.cornell.edu/grape-cold-hardiness" target="blank">Grape Cold Hardiness Risk Model</a> at this time.</mark></h1>
          <p className="text-center md:text-lg">
            Choose or edit your favorite grid points
          </p>
        </div>
      </section>

      <p className="max-w-2xl px-6 py-1 mx-auto md:text-lg lg:px-0">
        Click or tap the map to add a new favorite grid point. 
        Use the Remove button to remove them. 
        Use the Edit Name button to give the point a name. 
        Choices and edits are saved immediately.
      </p>

      <section className="pt-8">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 px-4 pb-10 gap-x-12 xl:grid-cols-2 favorites">
            <Card
              title="Your Favorite Grid Points"
              color="primary"
              icon={true}
              favoriteStations={favoriteGridPoints}
            >
              <div className="flex flex-col">
                <div className="w-full px-2 mx-auto mb-4 mt-2 flex flex-col justify-center items-center">
                  <h3 class='underline'>Active Grid Point</h3>
                  <p class='p-0'>{gridPoint.name === 'Custom' ? `${gridPoint.name} (${gridPoint.latitude}, ${gridPoint.longitude})` : gridPoint.name}</p>
                </div>

                {/* Favorite Grid Points List */}
                <div className="overflow-y-scroll h-96">
                  {favoriteGridPoints.length !== 0 &&
                    favoriteGridPoints.map(({name, latitude, longitude}, i) => {
                      return (
                        <div
                          key={i}
                          className={`px-6 py-2 mb-2 ${
                            i % 2 === 0 ? 'white' : 'bg-gray-100'
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <span className="mr-2">
                              <svg
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-6 h-6 text-orange-400 star"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                              </svg>
                            </span>
                            <div className="w-full">
                              <span className="text-xs text-left sm:text-sm md:text-base">
                                {name === 'Custom' ? `${name} (${latitude}, ${longitude})` : name}
                              </span>
                            </div>
                            <button
                              className={`px-2 py-1 font-semibold tracking-wide text-gray-600 bg-white border border-gray-600 hover:bg-gray-600 hover:text-white text-xs rounded-md mr-1`}
                              style={{ minWidth: '78px', maxWidth: '78px' }}
                              onClick={() => setRename({name, index: i})}
                            >
                              Edit Name
                            </button>
                            <button
                              className={`px-2 py-1 font-semibold tracking-wide text-gray-600 bg-white border border-gray-600 hover:bg-gray-600 hover:text-white text-xs rounded-md`}
                              onClick={() => removeFavoriteGridPoint(i)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </Card>

            <div
              className="mt-12 xl:mt-0 w-full"
              aria-label="Favorite grid points map"
            >
              <Card
                title="Click or tap on any location to add to favorites"
                color="primary"
              >
                <div
                  aria-hidden="true"
                  className="relative h-96 lg:h-128 w-full"
                >
                  {gridPoint && (
                    <MapGridData
                      gridPoint={gridPoint}
                      setGridPoint={setGridPoint}
                      favoriteGridPoints={favoriteGridPoints}
                      geoJSON={gridPointGeoJSON}
                      addFavoriteGridPoint={addFavoriteGridPoint}
                      tabNum={tabNum}
                    ></MapGridData>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <Dialog
        isOpen={rename !== null}
        onDismiss={close}
        aria-labelledby='rename-grid-point'
        className="max-w-lg rounded-lg"
      >
        <button className="text-3xl close-button" onClick={close}>
          <VisuallyHidden>Close</VisuallyHidden>
          <span aria-hidden>×</span>
        </button>
        <div className="input-container">
          <label style={{ fontSize: '14px' }} htmlFor='rename-input'>New Grid Point Name</label>
          <input
            type='text'
            id='rename-input'
            value={rename === null ? '' : rename.name}
            onChange={(e) => setRename({ ...rename, name: e.target.value })}
            className="custom-input"
            style={{ padding: '6px 10px' }}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            onClick={() => {
              editFavoriteGridPoint(rename)
              setRename(null)
            }}
            className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            Save
          </button>
        </div>
      </Dialog>
    </div>
  )
}
