import { useQuery } from "react-query";
import { fetchStationData } from "../../../utils/fetchData";

export default function useFetchStationData(
  station = null,
  startDate = null,
  currentDate = null
) {
  // Get dataset for the user-defined timeframe
  let sid = "";
  if (station !== null) sid = station.id;

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}00`;
  }

  const currentYear = new Date().getFullYear();
  let sdate = `${currentYear}010100`;
  if (startDate) sdate = formatDateToYYYYMMDD(startDate);

  let edate = "now";
  if (currentDate) edate = formatDateToYYYYMMDD(currentDate);

  function useFetchData(sid, sdate, edate, station) {
    const body = {
      sid,
      sdate,
      edate,
      extraelems: station ? station.extraelems : "",
    };

    const { data, isLoading, isError } = useQuery(
      `${body.sdate}-${body.edate} (${sid})`,
      () => fetchStationData(body),
      {
        refetchOnWindowFocus: false,
        enabled: station !== null,
        refetchOnMount: false,
        cacheTime: 900000, // data remains in memory for 15 minutes
      }
    );
    if (isError) {
      // Handle the error here, you can log it or take any necessary actions
      console.error("An error occurred while fetching data:", isError);
    }
    return { data, isLoading, isError };
  }

  const fetchDataResult = useFetchData(sid, sdate, edate, station);

  // Get the latest download in the past 28 days
  // let now = new Date();
  // let twentyEightDaysAgo = new Date(now);
  // twentyEightDaysAgo.setDate(now.getDate()-28);
  // const year = twentyEightDaysAgo.getFullYear();
  // const month = String(twentyEightDaysAgo.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1 and pad with 0 if needed.
  // const day = String(twentyEightDaysAgo.getDate()).padStart(2, '0');
  // const formattedTwentyEightDaysAgo = `${year}${month}${day}00`;
  // console.log("Twenty eight days ago: ", formattedTwentyEightDaysAgo);

  function useGetLatestTimestamp() {
    const sid = station ? station.id : "gen newa";
    let now = new Date(); // Calculate 'now' inside the function
    // console.log("now via useGetLatestTimestamp:", now);
    let twentyEightDaysAgo = new Date(now);
    twentyEightDaysAgo.setDate(now.getDate() - 28);
    const year = twentyEightDaysAgo.getFullYear();
    const month = String(twentyEightDaysAgo.getMonth() + 1).padStart(2, "0");
    const day = String(twentyEightDaysAgo.getDate()).padStart(2, "0");
    const formattedTwentyEightDaysAgo = `${year}${month}${day}00`;

    const sdate = formattedTwentyEightDaysAgo;
    const edate = "now"; //formatDateToYYYYMMDD(now); // Use the current 'now' value
    // console.log('Now: ',edate)
    // console.log('28dago: ',sdate)

    const body = {
      sid,
      sdate,
      edate,
      extraelems: station ? station.extraelems : "",
    };

    const { data, isLoading, isError } = useQuery(
      `${body.sdate}-${body.edate} (${sid})`,
      () => fetchStationData(body),
      {
        refetchOnWindowFocus: false,
        enabled: station !== null,
        refetchOnMount: false,
        cacheTime: 6000, // data remains in memory for 15 minutes
      }
    );
    if (isError) {
      // Handle the error here, you can log it or take any necessary actions
      console.error("An error occurred while fetching data:", isError);
    }

    function formatDateString(dateString) {
      const date = new Date(dateString);

      // Get the month in long format (e.g., "January")
      const month = date.toLocaleString("en-US", { month: "long" });

      // Get the day and add leading zeros if needed
      const day = date.getDate().toString().padStart(2, "0");

      // Get the year
      const year = date.getFullYear();

      // Get the hour and minute and add leading zeros if needed
      const hour = date.getHours().toString().padStart(2, "0");
      const minute = date.getMinutes().toString().padStart(2, "0");

      // Construct the formatted string
      const formattedDate = `${month} ${day} ${year} ${hour}:${minute}`;

      return formattedDate;
    }

    if (data && data.hourlyData && data.hourlyData.length > 0) {
      const lastRecordTimestampRaw =
        data.hourlyData[data.hourlyData.length - 1].date;
      // console.log("lastRecordTimestampRaw:", lastRecordTimestampRaw);
      const lastRecordTimestampFormatted = formatDateString(
        lastRecordTimestampRaw
      );
      return { lastRecordTimestampFormatted, isLoading, isError };
    } else {
      return "January 01 1900 00:00";
    }
  }

  const getLatestTimestampResult = useGetLatestTimestamp();
  // console.log("latest timestamp", getLatestTimestampResult);

  return { fetchDataResult, getLatestTimestampResult };
}
