import React, { useState, useEffect } from "react";

const DailyRiskCardComponent = ({ data, indexOffset, selectedUnit }) => {
  const [date, setDate] = useState("");
  const [isForecast, setIsForecast] = useState("");
  const [lt50Celsius, setLt50Celsius] = useState(0);
  const [lt50Fahrenheit, setLt50Fahrenheit] = useState(0);
  const [lowC, setLowC] = useState(0);
  const [highC, setHighC] = useState(0);
  const [lowF, setLowF] = useState(0);
  const [highF, setHighF] = useState(0);

  useEffect(() => {
    if (data && data.length > 0 && data.length - indexOffset >= 0) {
      const latestData = data[data.length - (indexOffset+1)];
      if (latestData) {
        const {
          date,
          isForecast,
          lt50_celsius,
          lt50_fahrenheit,
          minT_celsius,
          maxT_celsius,
          minT_fahrenheit,
          maxT_fahrenheit,
        } = latestData;

        setDate(date);
        setIsForecast(isForecast);
        setLt50Celsius(lt50_celsius);
        setLt50Fahrenheit(lt50_fahrenheit);
        setLowC(minT_celsius);
        setHighC(maxT_celsius);
        setLowF(minT_fahrenheit);
        setHighF(maxT_fahrenheit);
      }
    }
  }, [data, indexOffset]);

  const lt50_temp =
    selectedUnit === "F" ? `${lt50Fahrenheit} °F` : `${lt50Celsius} °C`;
  const low_temp = selectedUnit === "F" ? `${lowF} °F` : `${lowC} °C`;
  const high_temp = selectedUnit === "F" ? `${highF} °F` : `${highC} °C`;

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    const formattedDate = new Date(year, month - 1, day); // Month is zero-indexed
    const options = { month: "long", day: "numeric", year: "numeric" };
    return formattedDate.toLocaleDateString("en-US", options);
  };

  const freezeRiskStyle = (isHighRisk) => ({
    backgroundColor: isHighRisk ? "red" : "green",
    padding: "10px",
    fontWeight: "bold",
    color: "white",
    borderRadius: "4px",
  });

  const riskDistance = lt50Celsius - lowC;

  const isHighRisk = riskDistance !== 0 && Math.abs(riskDistance) < 5;

  return (
    <div className="daily-risk-card">
      <h2>{date ? formatDate(date) : "Select a date..."}</h2>
      <h4>{isForecast ? "Forecast" : "Historical Record"}</h4>
      <br />
      <div
        className="freeze-risk-indicator"
        style={freezeRiskStyle(isHighRisk)}
      >
        {riskDistance === 0
          ? "No data..."
          : isHighRisk
          ? "HIGH FREEZE RISK"
          : "LOW FREEZE RISK"}
      </div>
      <br />
      <br />
      <div className="temperature-details">
        <div className="temperature-item">
          <h5>LT50*</h5>
          <p>
            <strong>{lt50_temp}</strong>
          </p>
        </div>
        <div className="temperature-item">
          <h5>Daily low temperature</h5>
          <p>
            <strong>{low_temp}</strong>
          </p>
        </div>
        <div className="temperature-item">
          <h5>Daily high temperature</h5>
          <p>
            <strong>{high_temp}</strong>
          </p>
        </div>
      </div>
      <br />
      <div>
        <p>*Predicted temperature at which 50% of buds will be damaged.</p>
      </div>
      <br />
    </div>
  );
};

export default DailyRiskCardComponent;
