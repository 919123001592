import React, { useEffect, useState, useCallback } from 'react'
import { useAuth0 } from '../../context/react-auth0-spa'
import { useLocalStorage } from './useLocalStorage'

const DEFAULT_GRID_POINT = { name: 'Geneva (default)', latitude: 42.8767, longitude: -77.031 };

export default function useGridPoints() {
  const { user, setUser, position } = useAuth0()
  const userGridPoint = position ? { name: 'Your Location', latitude: position.latitude, longitude: position.longitude} : null;
  const [gridPoint, setGridPoint] = useLocalStorage('newa_project_grid_point', userGridPoint || DEFAULT_GRID_POINT)

  const [favoriteGridPoints, setFavoriteGridPoints] = useState([])

  useEffect(() => {
    if (!user) return

    // If user, load favorite grid points or, if user position is available, create new favorite list with users' position as a favorite
    if (Object.keys(user).includes('favoriteGridPoints')) {
      setFavoriteGridPoints(user.favoriteGridPoints)
    } else if (userGridPoint) {
      setFavoriteGridPoints([userGridPoint])
      setUser({ ...user, favoriteGridPoints: [userGridPoint] })
    }
  }, [user])

  const addFavoriteGridPoint = useCallback((newFavorite) => {
    if (Object.keys(user).includes('favoriteGridPoints')) {
      const newFavs = JSON.parse(JSON.stringify(user.favoriteGridPoints));
      const existingFavorite = newFavs.findIndex((fav) => fav.latitude === newFavorite.latitude && fav.longitude === newFavorite.longitude);
      if (existingFavorite < 0) {
        newFavs.push(newFavorite);
        setUser({ ...user, favoriteGridPoints: newFavs.sort((a,b) => a.name > b.name) });
      }
    } else {
      setUser({ ...user, favoriteGridPoints: [newFavorite] });
    }
  }, [user, setUser]);

  const editFavoriteGridPoint = useCallback(({ name, index }) => {
    if (Object.keys(user).includes('favoriteGridPoints')) {
      const newFavs = JSON.parse(JSON.stringify(user.favoriteGridPoints));
      
      newFavs[index].name = name;
      if (newFavs[index].latitude === gridPoint.latitude && newFavs[index].longitude === gridPoint.longitude) {
        setGridPoint(newFavs[index]);
      }
      setUser({ ...user, favoriteGridPoints: newFavs.sort((a,b) => a.name > b.name) });
    }
  }, [user, setUser]);

  const removeFavoriteGridPoint = useCallback((removeIndex) => {
    if (Object.keys(user).includes('favoriteGridPoints')) {
      const newUser = { ...user };
      const toRemove = { ...newUser['favoriteGridPoints'][removeIndex] }
      newUser['favoriteGridPoints'].splice(removeIndex, 1)

      if (toRemove.name === gridPoint.name && toRemove.latitude === gridPoint.latitude && toRemove.longitude === gridPoint.longitude) {
        setGridPoint(userGridPoint || DEFAULT_GRID_POINT);
      }

      setUser(newUser);
    }
  }, [user, setUser]);

  let gridPointInFavorites = false;
  const geoJSON = {
    type: 'FeatureCollection',
    features: favoriteGridPoints.map(gp => {
      if (gp.name === gridPoint.name && gp.latitude === gridPoint.latitude && gp.longitude === gridPoint.longitude) gridPointInFavorites = true;
      return {
        type: 'Feature',
        properties: { name: gp.name, isFavorite: true },
        geometry: { type: 'Point', coordinates: [gp.longitude, gp.latitude] }
      };
    })
  }

  if (!gridPointInFavorites) {
    geoJSON.features.push({
      type: 'Feature',
      properties: { name: gridPoint.name, isFavorite: false },
      geometry: { type: 'Point', coordinates: [gridPoint.longitude, gridPoint.latitude] }
    });
  }

  return {
    favoriteGridPoints,
    gridPoint,
    setGridPoint,
    gridPointGeoJSON: geoJSON,
    addFavoriteGridPoint,
    removeFavoriteGridPoint,
    editFavoriteGridPoint
  }
}