import { Link } from "gatsby";
import React from "react";
import GlobalStateContext from "../../../context/globalStateContext";
import { useAuth0 } from "../../../context/react-auth0-spa";
import { determineStatePartner } from "../../../utils/helpers";

const SubHeader = ({ station, h1Text }) => {
    const { isAuthenticated } = useAuth0();
    const { DEFAULT_STATION } = React.useContext(GlobalStateContext);
    const [statePartner, setStatePartner] = React.useState(null);
    const defaultAffiliation = "Cornell University";
    const defaultAffiliationUrl = "https://cals.cornell.edu";
    const affiliation =
        statePartner &&
            statePartner.affiliation !== null &&
            statePartner.affiliation !== "Cornell University"
            ? statePartner.affiliation
            : defaultAffiliation;
    const affiliationUrl =
        statePartner &&
            statePartner.affiliationUrl !== null &&
            statePartner.affiliation !== "Cornell University"
            ? statePartner.affiliationUrl
            : defaultAffiliationUrl;

    // console.log("state partner:", statePartner);

    React.useEffect(() => {
        if (station) {
            const ss = determineStatePartner(station);
            if (ss) {
                setStatePartner(ss);
            } else {
                // Set statePartner to default values if DEFAULT_STATION is not available yet
                setStatePartner({
                    affiliation: defaultAffiliation,
                    affiliationUrl: defaultAffiliationUrl,
                });
            }
        }
    }, [station, DEFAULT_STATION]);

    const heading1Text = h1Text || "";
    // Style --------------------------------------------------------------------------
    const linkStyle = `text-gray-800 hover:text-gray-900 hover:border-gray-900 md:text-lg lg:text-xl font-extrabold md:font-bold  py-2 px-0 hover:border-b-2 whitespace-no-wrap`;
    const activeStyle = `text-primary-900 border-primary-900 border-b-2`;

    return (
        <div>
            <header className="px-4 ml-4 mr-4 mt-2 mb-6 items-center justify-center max-w-full mx-auto text-gray-700 no-underline">
                <h1 className="px-2 mt-6 text-3xl font-bold leading-none tracking-wider sm:text-4xl md:text-5xl text-center" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    {heading1Text}
                </h1>
                <p className="text-center" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                    <small>
                        Based on research at {' '}
                        <a
                            href="https://cals.cornell.edu/jason-londo"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Cornell University
                        </a>
                        {" "}and{" "}
                        <a
                            href="https://pasdept.wisc.edu/directory/amaya-atucha/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            University of Wisconsin-Madison
                        </a>
                        , with funding from {' '}
                        <a href="https://portal.nifa.usda.gov/web/crisprojectpages/1029870-coldsnap-an-online-bud-cold-hardiness-prediction-tool-to-assist-grapevine-management-decisions.html" target="blank">USDA National Institute of Food and Agriculture Grant #2023-68008-39274</a>
                        . Web app developed by{" "}
                        <a
                            href="https://cals.cornell.edu/new-york-state-integrated-pest-management"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NYSIPM
                        </a>
                        {" "}and{" "}
                        <a
                            href="https://www.nrcc.cornell.edu"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NRCC
                        </a>
                        .
                    </small>
                </p>
                <p className="text-center" style={{ maxWidth: '1200px', margin: '0 auto' }}><mark><strong>Use with caution.</strong> This model is for <u>demonstration and testing purposes </u>. Check with your local extension or university specialist before making management decisions.<em>Updated 1/9/2025.</em></mark></p>
            </header>
        </div>
    );
};

export default SubHeader;
